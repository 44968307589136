import React, {
  ChangeEvent,
  FormEvent,
  ReactText,
  useEffect,
  useState,
} from 'react';
import {
  resetPassword,
  confirmResetPassword,
  resendSignUpCode,
} from 'aws-amplify/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dialog } from '@reach/dialog';
import LoaderButton from '../components/LoaderButton';
import { Text, TextOnly } from '../components/Text';
import { authStorage, fieldChangeCase } from '../libs/utils-ts';
import { ExternalFooterLinks } from '../components/ExternalFooterLinks';
import LanguageSelector from '../components/LanguageSelector';
import AlertModal from '../components/AlertModal';
import { toast } from 'react-toastify';
import { login } from '../libs/db-lib';
import { useFormik } from 'formik';

interface FormFields {
  username?: string;
  password?: string;
}

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showModal, setShowModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [requestNewPassword, setRequestNewPassword] = useState(false);
  const [resendValidateCode, setResendValidateCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formFields, setFormFields] = useState<FormFields>({});
  const [previousToast, setPreviousToast] = useState<
    string | number | undefined
  >();
  const [hasSentConfirmationCode, setHasSentConfirmationCode] = useState(false);

  // @ts-ignore - possible that react router dom types need to be updated
  const from = location.state?.from?.pathname || '/viewAgreements';

  const alertMsg = location?.state?.emailVerified || null;
  const username = location?.state?.username;
  const success = location?.state?.success;
  let toastId = null;

  useEffect(() => {
    // Check the username and password fields to see is values have been autofilled
    if (username) {
      setFormFields({ username });
    }
    if (alertMsg) {
      if (success) {
        toastId = toast.success(alertMsg, {
          autoClose: false,
          containerId: 'standard',
        });
        setPreviousToast(toastId);
      } else {
        toastId = toast.error(alertMsg, {
          autoClose: false,
          containerId: 'standard',
        });
        setPreviousToast(toastId);
      }
    }
  }, []);

  const sendCodeFormik = useFormik({
    initialValues: {
      confirmUsername: '',
    },
    onSubmit: async (values, { setSubmitting, setFieldValue }) => {
      setSubmitting(true);
      try {
        await resetPassword({ username: values.confirmUsername })
          .then((data) => {
            const { nextStep } = data;
            if (
              nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE'
            ) {
              toast.success(TextOnly('confirmationCodeSent'), {
                containerId: 'standard',
              });
            } else {
              toast.success(TextOnly('codeSent'), { containerId: 'standard' });
            }
          })
          .then(() => {
            // show user next portion of form
            setHasSentConfirmationCode(true);
          })
          .catch((err) => {
            toast.error(err.message, {
              containerId: 'standard',
              autoClose: false,
            });
          });
      } catch (e) {
        setSubmitting(false);
        toast.error(JSON.stringify(e), {
          containerId: 'standard',
          autoClose: false,
        });
      }
    },
  });

  const changePasswordFormik = useFormik({
    initialValues: {
      confirmationCode: '',
      newPassword: '',
    },
    onSubmit: async (values, { setSubmitting, setFieldValue }) => {
      setSubmitting(true);
      try {
        // Collect confirmation code and new password, then
        await confirmResetPassword({
          username: sendCodeFormik.values.confirmUsername,
          confirmationCode: values.confirmationCode,
          newPassword: values.newPassword,
        })
          .then((data) => {
            setRequestNewPassword(true);
            toast.success(TextOnly('passwordChanged'), {
              containerId: 'standard',
            });
            // clear fields
            setFieldValue('confirmationCode', '');
            setFieldValue('newPassword', '');
          })
          .catch((err) => {
            toast.error(err.message, {
              containerId: 'standard',
              autoClose: false,
            });
          });
      } catch (e) {
        // setAlertMessage(e.message);
        // setShowAlertModal(true);
        setSubmitting(false);
      }
    },
  });

  const resendValidationFormik = useFormik({
    initialValues: {
      username: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await resendSignUpCode({ username: values.username });
        toast.success(TextOnly('confirmationCodeSent'), {
          containerId: 'standard',
        });
      } catch (e) {
        setSubmitting(false);
        toast.error(TextOnly('failedToSendConfirmationCode'), {
          containerId: 'standard',
          autoClose: false,
        });
      }
      setResendValidateCode(false);
    },
  });

  useEffect(() => {
    // TODO: check verify user email & set props - Chance Smith 1/17/22
    //   // First check if we are verifying an email address
    //   if (props.verifyUserEmail) {
    //     props.history.push({
    //       pathname: '/verifyUserEmail',
    //       state: {
    //         code: props.code,
    //         username: props.username,
    //         clientId: props.clientId,
    //         email: props.email,
    //         language: props.language,
    //       },
    //     });
    //   } else {
    //     if (props.isAuthenticated) {
    //       props.history.push({
    //         pathname: '/myActivity',
    //       });
    //     }
    //     if (props?.location?.state?.previousToast) {
    //       setState({
    //         previousToast: props.location.state.previousToast,
    //       });
    //     }
    //     let username =
    //       props?.location?.state?.username ||
    //       document.getElementById('username') ||
    //       '';
    //     let alertMsg = props?.location?.state?.emailVerified || null;
    //     // Check the username and password fields to see is values have been autofilled
    //     if (username.length > 0) {
    //       setState({
    //         username: username,
    //       });
    //       if (alertMsg) {
    //         if (props?.location?.state?.success) {
    //           const toastId = toast.success(alertMsg, {
    //             autoClose: false,
    //             containerId: 'standard',
    //           });
    //           setState({ previousToast: toastId });
    //         } else {
    //           const toastId = toast.error(alertMsg, {
    //             autoClose: false,
    //             containerId: 'standard',
    //           });
    //           setState({ previousToast: toastId });
    //         }
    //       }
    //     }
    //     let password = document.getElementById('password');
    //     if (password.length > 0) {
    //       setState({
    //         password: password,
    //       });
    //     }
    //   }
  }, []);

  function validateForm() {
    if (!formFields.username || !formFields.password) return false;
    return formFields.username.length > 0 && formFields.password.length > 0;
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.id === 'username') {
      fieldChangeCase(formFields, setFormFields, event.target, 'lower', false);
    } else {
      setFormFields({
        ...formFields,
        [event.target.id]: event.target.value,
      });
    }
  };

  const handleShowResetPassword = () => {
    setRequestNewPassword(true);
    sendCodeFormik.setFieldValue('confirmUsername', formFields.username);
  };

  const handleResendConfirm = () => {
    setResendValidateCode(true);
    resendValidationFormik.setFieldValue(
      'confirmUsername',
      formFields.username
    );
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleAlertCancel = () => {
    setShowAlertModal(false);
  };

  const handleCancelPasswordModal = () => {
    // @ts-ignore
    // TODO: handle possible null obj - Chance Smith 1/17/22
    document.querySelector('.c-modal-slider').classList.add('closed');
    setTimeout(() => {
      setRequestNewPassword(false);
      setIsLoading(false);
    }, 350);
  };

  const handleCancelResendValidateCodeModal = () => {
    // @ts-ignore
    document.querySelector('.c-modal-slider').classList.add('closed');
    setTimeout(() => {
      setResendValidateCode(false);
      setIsLoading(false);
    }, 350);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    setIsLoading(true);

    if (previousToast) {
      toast.dismiss(previousToast);
    }

    if (!formFields.username || !formFields.password) {
      // TODO: trigger toast for error message - Chance Smith 1/18/22
      alert(TextOnly('usernameAndPasswordRequired'));
      return;
    }

    const response = await login({
      username: formFields.username,
      password: formFields.password,
    });

    if (!response.error) {
      // save the token in user in local storage
      response.LastAuthUser = formFields.username;
      authStorage.setLoginTokens(response);

      navigate(from, { replace: true });
    } else {
      let errMsg = response.error;
      if (
        errMsg === 'User does not exist.' ||
        errMsg === 'Incorrect username or password.' ||
        errMsg === 'InvalidParameterException'
      ) {
        errMsg = TextOnly('incorrectUserAndPass');
      } else if (errMsg === 'User is not confirmed.') {
        errMsg = TextOnly('userNotConfirmed');
      }
      const toastId = toast.error(errMsg, {
        autoClose: false,
        containerId: 'standard',
      });
      setPreviousToast(toastId);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="l-container-lg l-full-height">
        <div className="l-form-column u-margin-top-large">
          <LanguageSelector />
        </div>
        <div className="l-form-column">
          <form onSubmit={handleSubmit}>
            <h2 className="u-text-center h1 u-padding-bottom-large u-margin-top-none">
              <Text tid="userLogin" />
            </h2>
            <div className="c-field">
              <input
                id="username"
                maxLength={100}
                type="text"
                autoFocus
                className="c-input"
                placeholder={TextOnly('username')}
                value={formFields?.['username'] || ''}
                onChange={handleChange}
                autoCapitalize="off"
              />
            </div>
            <div className="c-field">
              <input
                id="password"
                maxLength={50}
                type="password"
                className="c-input"
                placeholder={TextOnly('password')}
                value={formFields?.['password'] || ''}
                onChange={handleChange}
              />
            </div>
            <div className="u-text-center u-margin-bottom-large u-padding-top-large">
              <button
                type="button"
                className="c-btn-link-text"
                onClick={handleShowResetPassword}
              >
                <div className="c-btn__inner">
                  <Text tid="forgotPassword" />
                </div>
              </button>
            </div>

            <div className="u-text-center u-margin-bottom-large u-padding-bottom-large">
              <button
                type="button"
                className="c-btn-link-text"
                onClick={handleResendConfirm}
              >
                <Text tid="resendConfirmLink" />
              </button>
            </div>
            <LoaderButton
              className="c-btn-full"
              disabled={!validateForm()}
              type="submit"
              isLoading={isLoading}
              text={TextOnly('login')}
              loadingText={TextOnly('loggingIn')}
            />
          </form>
        </div>
        <div className="l-form-column" />
        <ExternalFooterLinks />
      </div>
      <Dialog
        isOpen={showModal}
        onDismiss={handleCancel}
        className="c-modal"
        aria-label={TextOnly('loginError')}
      >
        <button
          className="c-btn-icon c-modal__close"
          onClick={handleCancel}
          type="button"
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fa fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          <Text tid="loginError" />
        </h1>
      </Dialog>

      <Dialog
        isOpen={requestNewPassword}
        onDismiss={handleCancelPasswordModal}
        className="c-modal-slider"
        aria-label={TextOnly('changePassword')}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={handleCancelPasswordModal}
          type="button"
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fa fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          <Text tid="changePassword" />
        </h1>

        <div className="c-modal__body">
          {!hasSentConfirmationCode ? (
            <form onSubmit={sendCodeFormik.handleSubmit}>
              <div className="c-field">
                <label htmlFor="confirmUsername" className="c-field__label">
                  <Text tid="username" />
                </label>
                <input
                  maxLength={50}
                  id="confirmUsername"
                  name="confirmUsername"
                  autoFocus
                  placeholder={TextOnly('username')}
                  className="c-input"
                  value={sendCodeFormik.values.confirmUsername}
                  onChange={sendCodeFormik.handleChange}
                  type="text"
                  autoCapitalize="off"
                />
              </div>
              <LoaderButton
                type="submit"
                text={TextOnly('sendConfirmationCode')}
                isLoading={sendCodeFormik.isSubmitting}
                loadingText={TextOnly('sending')}
              />{' '}
              <button
                className="c-btn-outline"
                onClick={handleCancelPasswordModal}
                type="button"
              >
                <Text tid="cancel" />
              </button>
            </form>
          ) : (
            <form onSubmit={changePasswordFormik.handleSubmit}>
              <p>
                <Text tid="accountValidationCodeSent" />
              </p>
              <div className="c-field">
                <label htmlFor="confirmationCode" className="c-field__label">
                  <Text tid="confirmationCode" />
                </label>
                <input
                  maxLength={50}
                  id="confirmationCode"
                  name="confirmationCode"
                  autoFocus
                  autoComplete="confirmation-code"
                  placeholder={TextOnly('confirmationCode')}
                  className="c-input"
                  value={changePasswordFormik.values.confirmationCode}
                  onChange={changePasswordFormik.handleChange}
                  type="text"
                />
              </div>
              <div className="c-field">
                <label htmlFor="newPassword" className="c-field__label">
                  <Text tid="enterNewPassword" />
                </label>
                <input
                  maxLength={50}
                  id="newPassword"
                  autoComplete="new-password"
                  placeholder={TextOnly('newPassword')}
                  className="c-input"
                  value={changePasswordFormik.values.newPassword}
                  onChange={changePasswordFormik.handleChange}
                  type="password"
                />
              </div>
              <button className="c-btn" type="submit" onClick={handleCancel}>
                <Text tid="changePassword" />
              </button>{' '}
              <button
                className="c-btn-outline"
                onClick={handleCancelPasswordModal}
                type="button"
              >
                <Text tid="cancel" />
              </button>
            </form>
          )}
        </div>
      </Dialog>

      <Dialog
        isOpen={resendValidateCode}
        onDismiss={handleCancelResendValidateCodeModal}
        className="c-modal-slider"
        aria-label={TextOnly('resendConfirmLink')}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={handleCancelResendValidateCodeModal}
          type="button"
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fa fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          <Text tid="resendConfirmLink" />
        </h1>

        <div className="c-modal__body">
          <form onSubmit={resendValidationFormik.handleSubmit}>
            <div className="c-field">
              <label htmlFor="username" className="c-field__label">
                <Text tid="username" />
              </label>
              <input
                maxLength={50}
                id="username"
                name="username"
                autoFocus
                placeholder={TextOnly('username')}
                className="c-input"
                value={resendValidationFormik.values.username}
                onChange={resendValidationFormik.handleChange}
                type="text"
                autoCapitalize="off"
              />
            </div>
            <LoaderButton
              type="submit"
              text={TextOnly('sendConfirmationCode')}
              isLoading={resendValidationFormik.isSubmitting}
              loadingText={TextOnly('sending')}
            />{' '}
            <button
              className="c-btn-outline"
              onClick={handleCancelResendValidateCodeModal}
              type="button"
            >
              <Text tid="cancel" />
            </button>
          </form>
        </div>
      </Dialog>

      <AlertModal
        message={''}
        showModal={showAlertModal}
        handleCancel={handleAlertCancel}
      />
    </>
  );
};

export default Login;
